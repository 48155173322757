@import 'variables';

.app-nav {
	position: fixed;
	top: 0;
	left: 50%;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	transform: translateX(-50%);
	background: rgba(0, 0, 0, 0.7);
	z-index: 5;

	ul {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		max-width: calc(100% - 4em);
		margin: 0 2em;
	}

	li {
		margin: 0 1em;
		list-style: none;
	}

	a:link,
	a:active,
	a:visited {
		display: block;
		padding: 0.666em 1.25em 1em;
		font-size: 1em;
		color: #fff;
		border-top: 3px solid transparent;
		text-decoration: none;
		text-transform: uppercase;

		&.nav-active {
			color: $green-lt;
			border-top-color: $green-lt;
		}
	}
}

@media only screen and (max-width: $bp-mobile) {

	.app-nav {
		// top: auto;
		// bottom: 0;

		ul > li {
			margin: 0;
		}
	}
}

