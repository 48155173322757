@use "sass:math";

/* BREAKPOINTS */
$bp-min: 320px;
$bp-mobile: 600px;
$bp-tablet: 900px;
$bp-desktop: 1200px;
$bp-lgdesktop: 1600px;

/* COLORS */
$grey: #282c34;
$grey-dk: #0d0e0e;

$green-lt: #adffa8;

/* MIXIN - LINEAR-INTERPOLATION */

@function linear-interpolation($map, $xtra:0) {
	$keys: map-keys($map);
	@if (length($keys) != 2) {
		@error 'linear-interpolation() $map must be exactly 2 values';
	}
	// The slope
	$m: math.div(map-get($map, nth($keys, 2)) - map-get($map, nth($keys, 1)), nth($keys, 2) - nth($keys, 1));

	// The y-intercept
	$b: map-get($map, nth($keys, 1)) - $m * nth($keys, 1);

	// Determine if the sign should be positive or negative
	$sign: '+';
	@if ($b < 0) {
		$sign: '-';
		$b: abs($b);
	}

	@if ($xtra == 0) {
		@return calc(#{$m*100}vw #{$sign} #{$b});
	} @else {
		@return calc(#{$m*100}vw #{$sign} #{$b} + #{$xtra});
	}
}

