.tags-container {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-start;
}

.btn-tag {
	white-space: nowrap;

	.btn-text {
		transform: translateX(0);
		transition: 200ms transform;
	}

	.icon {
		position: absolute;
		opacity: 0;
		top: calc(50% + 0.015em);
		left: 0.75em;
		margin: 0;
		font-size: 0.666em;
		transform: translateY(-50%);
		transition: 200ms opacity;
	}

	.tag-active,
	.tag-active:hover {

		.btn-text {
			transform: translateX(0.5em);
		}
	}

	.tag-active {

		.icon-check {
			opacity: 1;
		}

		.icon-cross {
			opacity: 0;
		}
	}

	.tag-active.initialized:hover {

		.icon-check {
			opacity: 0;
		}

		.icon-cross {
			opacity: 1;
		}
	}
}
