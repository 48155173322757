@import 'variables';


.app-header {
	display: flex;
	flex: 1 0 auto;
	flex-flow: column nowrap;
	order: 1;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 48em;
	margin: 0 auto;
	padding: 2em 1em 1em;
	font-size: calc(10px + 2vmin);
	color: #fff;

	.company-name {
		color: $green-lt;
	}

	h1,
	h6 {
		font-weight: 200;
		text-transform: lowercase;
	}

	h1 	> span {
		text-transform: uppercase;
	}


	h2 {
		margin: auto 0 0.2em;
		font-weight: normal;
	}

	h5 {
		margin: 0.2em 0 auto;
		font-size: 0.55em;
		text-transform: uppercase;
	}

	h6 {
		margin-top: 1em;
	}

	h5,
	h6 {
		> span {
			display: inline-block;
			padding: 0 2em;
			border-right: 1px solid rgba(#fff, 0.5);

			&:last-child {
				border: 0;
			}
		}
	}
}
