@import 'variables';

.btn {
	position: relative;
	margin: 0 0.5em 0.5em;
	font-size: 1.2em;
	font-weight: 600;
	line-height: 1.6;
	cursor: pointer;
	appearance: none;

	&.btn-sm {
		font-size: 1em;
	}

	&.btn-lg {
		font-size: 1.5em;
	}

	> a,
	> .btn-inner {
		display: inline-flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: baseline;
		height: 100%;
		padding: 0.333em 1em;
		color: rgba(#000, 0.7);
		border: 2px solid #fff;
		transition: color 200ms, background-color 300ms, border-color 350ms;
		background-color: #fff;
		text-decoration: none;
	}

	.icon {
		display: inline-block;
		position: relative;
		top: 0.15em;
	}

	.icon:first-child {
		margin-right: 1em;
	}

	.icon:last-child {
		margin-left: auto;
	}

	&:hover {

		> a,
		> .btn-inner {
			color: #fff;
			background-color: transparent;
		}
	}

	&.btn-icon,
	&.btn-outline {

		> a,
		> .btn-inner {
			color: #fff;
			border-color: #fff;
			background-color: transparent;
		}

		&:hover {

			> a,
			> .btn-inner {
				color: rgba(#000, 1);
				border-color: rgba(#fff, 1);
				background-color: rgba(#fff, 1);
			}
		}
	}

	&.btn-link {
		display: inline-block;

		> a,
		> .btn-inner {
			padding: 0.1em;
			color: $green-lt;
			border: 0;
			border-bottom: 0.02em solid transparent;
			background-color: transparent;
		}

		&:hover {

			> a,
			> .btn-inner {
				border-color: $green-lt;
			}
		}
	}

	&.btn-icon {
		display: inline-block;

		> a,
		> .btn-inner {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 0.5em 0.5em 0.2em;
			font-size: 0.8em;
			text-align: center;

			.icon {
				margin: 0 0 0.333em;
				font-size: 2em;
			}
		}
	}

	&.btn-tag {

		> a,
		> .btn-inner {
			line-height: 1;
		}

		.tag-active {
			color: $green-lt;
			border-color: $green-lt;
			background-color: transparent;
		}

		.tag-active.initialized:hover {
			color: #fff;
			border-color: #fff;
			background-color: transparent;
		}
	}
}
