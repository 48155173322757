@import 'variables';

main {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	padding: 3em 0;
	overflow-x: hidden;
	overflow-y: auto;

	&.noscroll {
		overflow-y: hidden;
	}
}

.app-content {
	display: flex;
	flex: 1 0 auto;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: center;
}

.services {
	flex: 1 0 6em;
	width: 100%;

	h6 {
		font-size: 2em;
		color: $green-lt;
		font-weight: 200;
		text-transform: lowercase;
	}
}

[class*='-content'] {
	width: 100%;
	max-width: 80em;
	margin: 0 auto;
	padding-right: 2em;
	padding-left: 2em;
	position: relative;

	@media only screen and (max-width: $bp-mobile) {
		padding-right: 0.5em;
		padding-left: 0.5em;
	}

	> * {
		color: #fff;
	}

	&.loading {
		.icon {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 4em;
			height: 4em;
			opacity: 0.2;
			color: #fff;
			transform: translate(-50%, -50%);
			z-index: 2;
			animation: 0.5s infinite spin;

			@for $i from 1 through 5 {
				&:nth-child(#{$i}) {
					animation-delay: ($i - 1) * 250ms;
					animation-duration: $i * 0.5s;
				}
			}
		}
	}
}

.projects .tags-container {
	flex: 0 1 100%;
	justify-content: center;
	margin: 1em 0 2em;
}
