.award {
	display: flex;
	position: relative;
	// width: 100%;
	max-height: 100%;
	margin: 0 0.5em 0.5em;
	padding: 0 0.5em 2em;
	flex-flow: column nowrap;

	img {
		width: calc(100% + 1.4em);
		transform: translate(-0.7em, 1em);
	}

	[src*='laurel-default'] {
		opacity: 0.5;
		width: 100%;
		max-height: 95%;
		transform: translate(0, 0.5em);

		+ figcaption {
			display: block;
		}
	}

	figcaption {
		display: none;
		font-size: 0.8em;
		text-align: center;

		.award-name,
		.award-provider {
			display: block;
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0);
		}

		.award-provider {
			top: 50%;
			font-weight: bold;
			transform: translate(-50%, calc(-50% - 0.5em));
			text-shadow: 0 1px rgba(0, 0, 0, 0.8);
		}

		.award-name {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			min-height: 2em;
			bottom: 0.02em;
			line-height: 1;
			font-size: 0.9em;
		}
	}
}
