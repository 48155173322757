@import 'variables';

$gated-steps: 'initial',
							'request',
							'pending',
							'success';

.video-wrap {
	max-width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	overflow: hidden;

	video,
	iframe,
	.video-overlay {
		display: block;
		position: absolute;
		max-width: 100%;
		height: 100%;
		overflow: hidden;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0;
		padding: 0;
		border: 0;
	}

	video,
	iframe {
		opacity: 0;
		z-index: 1;
		transition: 600ms opacity;
	}

	&.ready iframe {
		opacity: 1;
	}

	&.ready .video-overlay.loading {
		opacity: 0;
		transition: 600ms opacity;
		pointer-events: none;
	}

	.video-overlay {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		z-index: 2;
		text-align: center;
		background-color: #000;
		background-size: cover;
		background-repeat: no-repeat;

    > .btn {
      margin: auto 3em 1em;
	    z-index: 2;

      ~ .btn {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 3em;
      }
    }

		&.loading {
			opacity: 1;

			.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 4em;
				height: 4em;
				opacity: 0.2;
				color: #fff;
				transform: translate(-50%, -50%);
				z-index: 2;
				animation: 2s infinite spin;

				@for $i from 1 through 5 {
					&:nth-child(#{$i}) {
						animation-delay: $i * 1.5 * 250ms;
						animation-duration: $i * 2s;
					}
				}
			}
		}

		[class^='gated-'] {
			display: none;
			z-index: 2;

			 > * {
				margin: 0.5em 1em;
			}

			> .btn:not(.btn-link) {
				margin-top: 2em;
			}
		}

		.btn-icon {
			width: 4em;
		}

		@for $i from 1 through length($gated-steps) {
			&.video-gated-#{nth($gated-steps, $i)} .gated-#{nth($gated-steps, $i)} {
				display: block;
			}
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: -2px;
			right: -2px;
			bottom: -2px;
			z-index: 1;
			background: linear-gradient(to bottom, transparent -25%, rgba(0, 0, 0, 0.9) 90%);
			transition: 2400ms background;
		}
	}
}

@keyframes spin {
	from { transform: translate(-50%, -50%) rotateZ(0deg); }
	to { transform: translate(-50%, -50%) rotateZ(360deg); }
}
